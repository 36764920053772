<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <loader v-show="isLoading" />

    <div v-show="!isLoading">
      <admin-header
        :title="
          !isEditPage ? 'Новый оператор' : formData.typeS || 'Оператор'
        "
        :to="{ name: 'admin', query:{ tab: 'operators'} }"
      >
        <sup
          class="text-c13 color-fargo"
          v-if="!isSave && isEditPage"
        >Не сохранено</sup>
      </admin-header>

      <div class="form mt-8 flex">
        <div class="flex flex-1 flex-direction-column">
          <div class="flex">
            <img-uploader
              class="mr-6"
              @change="onUpload($event, 'logoUrl')"
              @onViewImage="onViewImage"
              @deleteFile="deleteFile"
              upload-text="Загрузить фото"
              :src="formData.logoUrl"
            />
            <r-input
              class="mb-6 flex-1"
              label="Наименование"
              v-model="$v.formData.name.$model"
              @blur="isChange('name')"
              :class="{ field_error: $v.formData.name.$error }"
              :error="$v.formData.name.$error && !$v.formData.name.required"
              error-message="Поле обязательно для заполнения"
            />
          </div>
          <p class="taleggio mt-10">
            Маркер
          </p>
          <img-uploader
            class="mt-6"
            @change="onUpload($event, 'markerUrl')"
            @onViewImage="onViewImage"
            @deleteFile="deleteFile"
            upload-text="Загрузить маркер"
            :src="formData.markerUrl"
          />
          <!-- <r-drag-upload-file
            class="mt-6"
            multiple
            :count-file="1"
            upload-url="/uploadfile/index.php"
            :value="formData.markerUrl"
            @input="loadFile"
            :count-line="1"
            :params-body="{ upload_url: 'cells'}"
            description="или перетяните сюда файл объёмом не более 50 МБайт"
            :accept-file="['.jpg', '.jpeg', '.png', '.gif', '.svg']"
          />-->
          <r-button-action
            v-if="isEditPage && $store.getters.userIsRoot"
            class="color-fargo sulguni mt-10"
            title="Удалить оператора"
            icon="delete"
            color="fargo"
            @click="onDelete"
          />
        </div>
      </div>
      <div class="button_container">
        <r-button
          @click="submit"
          :disabled="isLoading || isSave"
          :title="isEditPage ? 'Сохранить' : 'Добавить'"
          width="wide"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import ModalCrop from '../components/ModalCrop';
import ModalViewImage from '../components/ModalViewImage';
import ImgUploader from '../components/ImgUploader';
import { formattedFiles } from '../helpers/utils';
import Loader from '../components/Loader';

export default {
  name: 'Operator',
  components: { Loader, AdminHeader, ImgUploader },
  validations: {
    formData: {
      name: {
        required
      }
    }
  },
  data() {
    return {
      formData: {
        logoUrl: null,
        name: null,
        markerUrl: null
      },
      isSave: false,
      flyButton: null,
      isLoading: false,
      loadedImg: null,
      emptyFormData: {},
      initialData: {},
      uploadMarker: []
    };
  },

  computed: {
    isEditPage() {
      return !!this.$route.params.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  created() {
    this.emptyFormData = this.formData;
  },
  mounted() {
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    if (!this.isEditPage) {
      this.isSave = false;
    }
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();

    if (!this.isEditPage) {
      this.isSave = false;
    }
  },

  methods: {
    loadFile(files) {
      if (!files?.length) {
        this.formData.markerUrl = [];
        return;
      }
      this.formData.markerUrl = formattedFiles(files);
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file

      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        originalSrc: file.src,
        type: file.type,
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        webkitRelativePath: file.webkitRelativePath
      };
      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        imageLoad: file,
        valName: target
      });
      this.isChange(target);
    },
    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: this.formData.logoUrl
      });
    },
    deleteFile() {
      this.formData.logoUrl = null;
      this.isChange('logoUrl');
    },

    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить оператора?',
        action: () => { this.delete(this.$route.params.id); }
      });
    },
    async delete(id) {
      await new Api().operateOperator({
        id,
        action: 'delete'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index',
          query: {
            tab: 'operators'
          }
        });
        window.location.assign(r.href);
      });
    },
    async setData() {
      this.isLoading = true;
      await this.$store.dispatch('getOperatorById', this.$route.params.id).then(res => {
        this.formData = { ...this.formData, ...res.all[0] };
        if (this.isEditPage) {
          this.isSave = true;
        }
      }).finally(() => {
        this.isLoading = false;
        this.initialData = JSON.parse(JSON.stringify(this.formData));
      });
    },
    resetData() {
      this.$v.$reset();
      this.formData = JSON.parse(JSON.stringify(this.emptyFormData));
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          id: this.$route.params.id ? this.$route.params.id : -1,
          action: 'update',
          item: this.formData
        };
        if (this.isCopy) {
          data.id = -1;
        }
        new Api()
          .operateOperator(data)
          .then(() => {
            const r = this.$router.resolve({
              name: 'index'
            });
            window.location.assign(r.href);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    isChange(field) {
      if (!this.isCopy) {
        this.isSave = this.initialData[field] === this.formData[field];
      }
    }
  }
};
</script>

<style scoped lang="scss">

.width-max {
  width: max-content !important;
}

.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

::v-deep .rir-modal__content {
  display: block !important;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.rotate-animation {
  animation: spin .8s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
