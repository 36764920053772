<template>
  <div>
    <div class="flex mb-6">
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        to="operator-add"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Добавить вручную
      </router-link>
    </div>
    <div class="flex mb-6 align-items-center">
      <r-input
        class="flex-1"
        label="Поиск"
        v-model="search"
        before-icon="search"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>
      <div class="objects__list mt-6">
        <operator-card
          v-for="card of filteredObjects"
          :key="card.id"
          :card="card"
        />
      </div>
      <not-found v-if="countObjects < 1" />
    </template>
  </div>
</template>

<script>
import OperatorCard from './OperatorCard';
import { wordMatch } from '../helpers/utils';
import NotFound from './NotFound';
import Loader from "./Loader";

export default {
  name: 'TabOperators',
  components: {
    Loader,
    OperatorCard,
    NotFound
  },
  data() {
    return {
      timeoutId: null,
      searchText: '',
      isShowMap: false
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.operators.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.operators.operators;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.name, this.searchText) <= 0.5
        );
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAllOperators');
  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .rir-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}
</style>
