<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <loader v-show="isLoading" />

    <div v-show="!isLoading">
      <admin-header
        :title="
          !isEditPage ? 'Новая БС сотовой связи' : formData.typeS || 'БС сотовой связи'
        "
        :to="{ name: 'admin', query:{ tab: 'stations'} }"
      >
        <sup
          class="text-c13 color-fargo "
          v-if="!isSave && isEditPage"
        >Не сохранено</sup>
      </admin-header>

      <div class="form mt-8 flex flex-1">
        <div class="flex flex-1 flex-direction-column">
          <r-select
            :class="{ field_error: $v.formData.operatorId.$error }"
            :items="$store.getters.getOperatorsForSelect"
            label="Оператор"
            v-model="formData.operatorId"
            @change="selectOperator"
            :error="$v.formData.operatorId.$error && !$v.formData.operatorId.required"
            error-message="Поле обязательно для заполнения"
          />
          <p class="mozzarella opacity-48 mt-6">
            Тип связи
          </p>
          <div
            class="field_wrapper mt-4"
            :class="{'disabled' : isLoraWan}"
          >
            <div class="flex align-items-center">
              <label
                v-for="checkbox of connectTypes"
                :for="'checkbox_' + checkbox.id"
                class="rir-checkbox mr-8 sulguni"
              >
                <input
                  :disabled="isLoraWan"
                  :id="'checkbox_' + checkbox.id"
                  type="radio"
                  name="type"
                  :value="checkbox.value"
                  v-model="formData.type"
                  @change="isChange('type')"
                >
                <span
                  class="rir-checkbox__input mr-1  "
                  style="flex-shrink: 0"
                >
                  <r-icon
                    size="16"
                    icon="ic_bullit-ok"
                    fill="amelie"
                    class="rir-checkbox__selected"
                  />
                </span>
                <span class="opacity-72">{{ checkbox.value }}</span>
              </label>
            </div>
            <div
              class="error mozzarella color-fargo bottom"
              v-if="$v.formData.type.$error && !$v.formData.type.required"
            >
              Поле обязательно для заполнения
            </div>
          </div>
          <div class="flex align-items-center mt-6">
            <r-input
              class="mr-6"
              label="Радиус действия, м"
              v-model="formData.radius"
              @blur="isChange('radius')"
              :readonly="formData.autoRadius"
            />
            <r-checkbox
              v-model="formData.autoRadius"
              @input="changeAutoRadius"
              label="Автоопределение"
            />
          </div>

          <r-button-action
            v-if="isEditPage && $store.getters.userIsRoot"
            class="color-fargo sulguni mt-10"
            title="Удалить БС"
            icon="delete"
            color="fargo"
            @click="onDelete"
          />
        </div>
        <div class="ml-6 flex flex-direction-column flex-1 overflow-hidden">
          <rir-map
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-6"
            :center="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
          >
            <ymap-marker
              :coords="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
              marker-id="marker"
              :options="{
                draggable: this.$store.getters.userIsRoot,
              }"
              :icon="operator.markerUrl ? {
                layout: 'default#image',
                imageSize: [56, 68],
                imageHref: operator.markerUrl
              } : $markerIcon('mapPin')"
              @dragend="onMarkerDragEnd"
            />
            <ymap-marker
              marker-type="Circle"
              marker-id="circle"
              :circle-radius="formData.radius"
              :coords="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
              :options="{
                fillColor: '#3D75E4',
                fillOpacity: '0.16',
                strokeWidth: 2,
                strokeColor: '#3D75E4'
              }"
            />
          </rir-map>
          <r-input
            class="flex-1 mb-6"
            label="Адрес"
            v-model="$v.formData.address.$model"
            :class="{ field_error: $v.formData.address.$error }"
            @blur="onSearchAddress(formData.address)"
            @onPressEnter="onSearchAddress(formData.address)"
            after-icon="search"
            :error="$v.formData.address.$error && !$v.formData.address.required"
            error-message="Поле обязательно для заполнения"
          />
          <r-input
            class="flex-1"
            label="Ориентир"
            v-model="formData.landmark"
            @blur="isChange('landmark')"
          />
        </div>
      </div>
      <div class="button_container">
        <r-button
          @click="submit"
          :disabled="isLoading || isSave"
          :title="isEditPage ? 'Сохранить' : 'Добавить'"
          width="wide"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import RirMap from '../components/RirMap';
import Loader from '../components/Loader';

export default {
  name: 'Object',
  components: { Loader, AdminHeader, RirMap },
  data() {
    return {
      canChange: false,
      isSave: false,
      flyButton: null,
      isLoading: false,
      isMapReady: false,
      emptyFormData: {},
      initialData: {},
      loadedImg: null,
      phone: null,
      email: null,
      radiuses: {
        EDGE: 25000,
        '3G': 1500,
        '4G': 500,
        LTE: 6000,
        LoraWAN: 900
      },
      connectTypes: [
        {
          id: 1,
          value: 'EDGE'
        },
        {
          id: 2,
          value: '3G'
        },
        {
          id: 3,
          value: 'LTE'
        }
      ],
      operator: {},
      formData: {
        operatorId: null,
        type: [],
        description: null,
        autoRadius: false,
        radius: null,
        lat: null,
        lng: null,
        address: null,
        landmark: null
      }
    };
  },
  validations: {
    formData: {
      operatorId: {
        required
      },
      address: {
        required
      },
      type: {
        required: requiredIf(data => !(data?.description?.toLowerCase() === 'lorawan'))
      }
    }
  },
  computed: {
    isLoraWan() {
      return this.formData?.description?.toLowerCase() === 'lorawan';
    },
    markerCoords() {
      const { lat } = this;
      const { lng } = this;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    isEditPage() {
      return !!this.$route.params.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  async created() {
    this.emptyFormData = this.formData;
    await this.$store.dispatch('getAllOperators');
  },
  mounted() {
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    if (!this.isEditPage) {
      this.isSave = false;
    }
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();

    if (!this.isEditPage) {
      this.isSave = false;
    }

    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
    this.activeTabId = 'information';
  },
  methods: {
    changeAutoRadius(e) {
      if (e) {
        this.formData.radius = null; // this.radiuses[this.formData.description];
      } else {
        this.formData.radius = this.initialData.radius;
      }
      this.isChange('autoRadius');
    },
    selectOperator(item) {
      this.formData.description = item.value;
      this.isChange('operatorId');
      this.operator = item;
    },
    async onSearchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.formData.address;
      }

      const res = await api.getAddress(JSON.stringify(data));
      this.formData.lat = res.lat;
      this.formData.lng = res.lng;
      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.formData.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.formData.address = res.address;
        }
      }
      this.isChange('address');
    },

    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить БС?',
        action: () => { this.delete(this.$route.params.id); }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'delete'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    async setData() {
      this.isLoading = true;
      await this.$store.dispatch('getObjectById', this.$route.params.id).then(async res => {
        this.formData = {
          ...this.formData,
          ...res.all[0]
        };
        await this.$store.dispatch('getOperatorById', this.formData.operatorId).then(res => {
          this.operator = res.all[0];
        });
        if (this.isEditPage) {
          this.isSave = true;
        }
      }).finally(() => {
        this.isLoading = false;
        this.initialData = JSON.parse(JSON.stringify(this.formData));
      });
    },
    onMarkerDragEnd(e) {
      if (!this.$store.getters.userIsRoot) return;
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.lat = coords[0];
        this.lng = coords[1];
        this.onSearchAddress(coords);
      }
    },

    async onMapClick(coords) {
      if (!this.$store.getters.userIsRoot) return;
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.onSearchAddress(coords);
    },

    resetData() {
      this.$v.$reset();
      this.formData = JSON.parse(JSON.stringify(this.emptyFormData));
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          id: this.$route.params.id ? this.$route.params.id : -1,
          action: 'update',
          item: {
            ...this.formData,
            radius: this.formData.autoRadius ? 0 : this.formData.radius
          }
        };
        if (this.isCopy) {
          data.id = -1;
        }
        new Api()
          .operateObject(data)
          .then(() => {
            const r = this.$router.resolve({
              name: 'index'
            });
            window.location.assign(r.href);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    isChange(field) {
      if (!this.isCopy) {
        this.isSave = this.initialData[field] === this.formData[field];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.disabled{
  pointer-events: none;
  opacity: .32;
}
.width-max {
  width: max-content !important;
}

.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

::v-deep .rir-modal__content {
  display: block !important;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.rotate-animation {
  animation: spin .8s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
