<template>
  <div>
    <div class="flex mb-6">
      <router-link
        class="mr-6"
        to="add"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-button-action
          title="Добавить вручную"
          icon="add"
        />
      </router-link>
      <r-button-action
        title="Добавить из базы данных"
        icon="add"
        @click="openModalUpload"
      />
    </div>
    <div class="flex mb-6 align-items-center">
      <r-select
        class="mr-4"
        :items="this.$store.getters.getOperatorsForSelect || []"
        label="Оператор"
        v-model="selectedSortOperators"
        return-object
      />
      <r-select
        class="flex-1 mr-6"
        :items=" [
          {
            id: 'BY_NAME',
            title: 'По названию'
          },
          {
            id: 'BY_SQUARE',
            title: 'По площади'
          }
        ]"
        label="Сортировать"
        v-model="selectedSorting"
      />
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model="search"
        before-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="menu"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          show-type
          @click="onMapClick"
        >
          <div v-if="filteredObjects.length > 0">
            <div
              v-for="(marker,index) of filteredObjects"
              :key="`_${marker.id}+${index}`"
            >
              <ymap-marker
                :coords="[marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="marker.markerUrl ? {
                  layout: 'default#image',
                  imageSize: [56, 68],
                  imageHref: marker.markerUrl
                } : $markerIcon('mapPin')"
                :options="{
                  hideIconOnBalloonOpen: false,
                  iconColor: !!Number(marker.contractConclude) ? '#3D75E4' : '#E14761'
                }"
                cluster-name="main"
                @click="onMarkerClick(marker.id)"
                @balloonopen="bindListener"
                @balloonclose="unbindListener"
              >
                <balloon-card
                  slot="balloon"
                  :marker="marker"
                  :data-id="marker.id"
                />
              </ymap-marker>
              <ymap-marker
                v-if="selectedMarkerId === marker.id"
                marker-type="Circle"
                marker-id="circle"
                :circle-radius="marker.radius"
                :coords="[marker.lat, marker.lng]"
                :options="{
                  fillColor: '#3D75E4',
                  fillOpacity: '0.16',
                  strokeWidth: 2,
                  strokeColor: '#3D75E4'
                }"
              />
            </div>
          </div>
        </rir-map>
      </div>
      <div
        v-else
        class="objects__list mt-6"
      >
        <card
          v-for="card of filteredObjects"
          :key="card.id"
          :card="card"
        />
        <not-found v-if="countObjects < 1" />
      </div>
    </template>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
    <message ref="message" />
  </div>
</template>

<script>
import Card from './Card';
import { wordMatch } from '../helpers/utils';
import NotFound from './NotFound';
import RirMap from './RirMap';
import ModalUpload from './ModalUpload';
import DeleteModal from './DeleteModal';
import Api from '../api/Api';
import BalloonCard from './BalloonCard';
import Loader from './Loader';
import Message from './Message';

export default {
  name: 'TabStations',
  components: {
    Loader,
    Card,
    NotFound,
    RirMap,
    BalloonCard,
    Message
  },
  data() {
    return {
      timeoutId: null,
      selectedView: 'table',
      searchText: '',
      selectedSorting: 'BY_NAME',
      sortType: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: '1',
          title: 'С договором'
        },
        {
          id: '0',
          title: 'Без договора'
        }
      ],
      selectedSortType: 'ALL',
      selectedSortOperators: [],
      sortOperators: [],
      isShowMap: false,
      selectedMarkerId: null
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.object.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.object.allObjects;

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.description || '', this.searchText) <= 0.5
            || wordMatch(el?.address || '', this.searchText) <= 0.5
        );
      }
      if (this.selectedSortType !== 'ALL') {
        list = list.filter(el => el.contractConclude === this.selectedSortType);
      }

      if (this.selectedSortOperators.length > 0) {
        list = list.filter(el => this.selectedSortOperators.find(operator => el.operatorId === operator.id));
      }
      if (this.selectedSorting === 'BY_NAME') {
        list = list.sort((a, b) => {
          const textA = a?.description?.toUpperCase() || '';
          const textB = b?.description?.toUpperCase() || '';
          return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
        });
      }
      if (this.selectedSorting === 'BY_SQUARE') {
        list = list.sort((a, b) => a.radius - b.radius);
      }
      list = list.map(el => {
        for (const operator of this.$store.state.operators.operators) {
          if (el.operatorId === operator.id) {
            el.markerUrl = operator.markerUrl;
            el.logoUrl = operator.logoUrl;
          }
        }
        return el;
      });
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAllObjects').then(res => {
      if (res.error) {
        this.$refs.message.showMessage(res.error, 'error');
      }
    });
    await this.$store.dispatch('getAllOperators').then(res => {
      if (res.error) {
        this.$refs.message.showMessage(res.error, 'error');
      }
    });
  },
  mounted() {
    this.sortOperators = this.$store.state.object.operators;
  },
  methods: {
    async openDeleteModal(id) {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить НТО?',
        action: () => { this.delete(id); }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'delete'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    openModalUpload() {
      this.$rir.modal.open(ModalUpload);
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    onMapClick() {
      this.selectedMarkerId = null;
    },

    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({
        name: this.activeTabId,
        params: { id: this.selectedMarkerId }
      });
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.rir-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.rir-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }

  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .rir-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}
</style>
