<template>
  <div class="objects">
    <admin-header
      title="БС сотовой связи"
      :back="false"
    />
    <r-tabs
      v-if="this.$store.getters.userIsRoot"
      class="my-7"
      :items="tabs"
      v-model="activeTabId"
    />
    <tab-stations v-if="activeTabId.id === 'stations'" />
    <tab-operators v-if="activeTabId.id === 'operators'" />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import TabStations from '../components/TabStations';
import TabOperators from '../components/TabOperators';

export default {
  name: 'ObjectList',
  components: {
    AdminHeader,
    TabStations,
    TabOperators
  },
  data() {
    return {
      activeTabId: {
        id: 'stations',
        title: 'Станции'
      },
      tabs: [
        {
          id: 'stations',
          title: 'Станции'
        },
        {
          id: 'operators',
          title: 'Операторы'
        }
      ]
    };
  },
  async activated() {
    if (this.$store.getters.userIsRoot) {
      this.activeTabId = this.tabs.find(item => item.id === this.$route.query.tab) || {
        id: 'stations',
        title: 'Станции'
      };
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
