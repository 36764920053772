import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/SELL_POINT_MOVE');
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  // objects
  async getAllObjects() {
    const { data } = await this._axios.post('/ajax.php?action=getCells');
    return data;
  }

  async getObjectById(id) {
    const { data } = await this._axios.get(`/ajax.php?action=getCells&id=${id}`);
    return data;
  }

  async operateObject(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateCell', { ...payload});
    return data;
  }

  // operators
  async getAllOperators() {
    const { data } = await this._axios.get('/ajax.php?action=getCellOperators');
    return data;
  }

  async getOperatorById(id) {
    const { data } = await this._axios.get(`/ajax.php?action=getCellOperators&id=${id}`);
    return data;
  }

  async operateOperator(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateCellOperator', { ...payload, currentMainMenuNumber: 17 });
    return data;
  }
}
